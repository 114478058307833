import { configureStore, combineReducers } from "@reduxjs/toolkit";
import initialDataReducer from "./initialData";
let rootReducer = combineReducers({
    // Add your reducers here
    initialData:initialDataReducer
  });

let store = configureStore({
    reducer:rootReducer,
});

export default store;