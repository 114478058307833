import React from "react";
import { Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Divider, Typography, Box } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PeopleIcon from "@mui/icons-material/People";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
 
const drawerWidth = 260;
 
const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, id: "dashboard", disabled: true },
    { text: "Appointments", icon: <EventNoteIcon />, id: "appointments", disabled: true },
    { text: "DocumentX", icon: <PeopleIcon />, id: "patients" },  // ✅ This remains enabled
    // { text: "Messages", icon: <MessageIcon />, id: "messages", disabled: true },
    { text: "PreCheckIn", icon: <MessageIcon />, id: "messages", disabled: true },
    { text: "SchedulerX", icon: <MessageIcon />, id: "messages", disabled: true },
    // { text: "Sample Data", icon: <MessageIcon />, id: "sample" },  // ✅ Enable Sample Data
];
 
const SideBar = ({ selectedPage, handleNavigation }) => {
    return (
        <Box
            variant="permanent"
            sx={{
                display:"flex",
                flexDirection:"column",
                width: drawerWidth,
                flexShrink: 0,
                marginTop: '-450px',
                
            }}
        >
            {/* 🔹 Top Logo Section */}
            {/* <Toolbar sx={{ textAlign: "center", display: "flex", justifyContent: "center", padding: 2 }}>
                <Typography variant="h4" sx={{ color: "white" }}>
                    HealthX
                </Typography>
            </Toolbar> */}
 
            <Divider />
 
            {/* 🔹 Centered Navigation Items */}
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 3, alignItems: "center", justifyContent: "center" }}>
                <List sx={{ width: "100%" }}>
                    {menuItems.map((item) => (
                        <ListItem key={item.id} disablePadding>
                            <ListItemButton
                                onClick={() => handleNavigation(item.id)}  // ✅ Enable click
                                sx={{
                                    backgroundColor: selectedPage === item.id ? "#EE3CFF" : "transparent",
                                    color: selectedPage === item.id ? "white" : "#94A3B8",
                                    "&:hover": { backgroundColor: "#EE3CFF", color: "white" },
                                    borderRadius: "8px",
                                    marginY: 1,
                                    width: "80%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                disabled={item.disabled} // ✅ Disable other buttons
                            >
                                <Box sx={{ color: selectedPage === item.id ? "white" : "#94A3B8", display: "flex", alignItems: "center", gap: 1 }}>
                                    {item.icon}
                                    <ListItemText primary={item.text} sx={{ textAlign: "center" }} />
                                </Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
 
            <Divider />
 
            {/* 🔹 Bottom Section - Settings & Logout */}
            {/* <List>
                <ListItem disablePadding>
                    <ListItemButton disabled sx={{ justifyContent: "center" }}>
                        <SettingsIcon sx={{ color: "#94A3B8", marginRight: 1 }} />
                        <ListItemText primary="Settings" sx={{ color: "#94A3B8" }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton disabled sx={{ justifyContent: "center" }}>
                        <LogoutIcon sx={{ color: "#94A3B8", marginRight: 1 }} />
                        <ListItemText primary="Logout" sx={{ color: "#94A3B8" }} />
                    </ListItemButton>
                </ListItem>
            </List> */}
        </Box>
    );
};
 
export default SideBar;