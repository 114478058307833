import { Box, Grid, Tooltip, Typography } from "@mui/material";
import Home_Bg from "../assets/AIQ_Demo.mp4";
import asistive from "../assets/icons/design-ideas_svgrepo.com.svg";
import visual from "../assets/icons/dimensional-view_svgrepo.com.svg";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateInitialState } from "../redux/initialData";
import health from "../assets/icons/circle-add-svgrepo-com.svg";
import manufacicon from "../assets/icons/set-up-svgrepo-com.svg";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const video_BG_ref = useRef(null);
    const [userRole, setUserRole] = useState(localStorage.getItem("user_role"));

    useEffect(() => {
        // Check if user is authenticated, if not redirect to login
        let token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        } else {
            dispatch(updateInitialState({ key: "login_token", value: token }));
            setUserRole(localStorage.getItem("user_role"));
        }

        if (video_BG_ref.current) {
            let video = video_BG_ref.current;
            video.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
            video.loop = true;
            video.autoplay = true;
            video.muted = true;
            video.playsInline = true;
            const videoPromise = video.play();
            if (videoPromise !== undefined) {
                videoPromise.catch((error) => {
                    console.error("Autoplay prevented:", error);
                });
            }
        }

        // Clear stored session data on browser/tab close or page reload
        const handleUnload = () => {
            localStorage.removeItem("token");
            localStorage.removeItem("user_role");
            localStorage.removeItem("selected_tile"); // Fix: Clear selected tile
        };

        window.addEventListener("beforeunload", handleUnload);
        window.addEventListener("unload", handleUnload);

        return () => {
            window.removeEventListener("beforeunload", handleUnload);
            window.removeEventListener("unload", handleUnload);
        };
    }, [dispatch, navigate]);

    const NavigateTiles = (tile) => {
        switch (tile) {
            case "cx":
                localStorage.setItem("selected_tile", "/assitive_ai");
                navigate("/assitive_ai");
                break;
            case "dna":
                localStorage.setItem("selected_tile", "/csv_qa");
                navigate("/csv_qa");
                break;
            case "health":
                localStorage.setItem("selected_tile", "/health_x");
                navigate("/health_x");
                break;
            case "manufac":
                localStorage.setItem("selected_tile", "/manufac_x");
                navigate("/manufac_x");
                break;
            default:
                break;
        }
    };

    return (
        <Grid container sx={{ width: "100vw", height: "100vh", backgroundImage: `url(${Home_Bg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", position: "relative" }}>
            <Box ref={video_BG_ref} component={"video"} src={Home_Bg} sx={{ width: "100%", height: "100%", objectFit: "cover" }} />
            <Grid item xs={12} sx={{ textAlign: "right", position: "absolute", right: "5%", bottom: "10%" }}>
                <Box sx={{ fontSize: "58px", fontWeight: 600, background: "linear-gradient(to right, #7044ff, #ee3cff, #ff8029) text", WebkitTextFillColor: "transparent" }}>IntelligentX</Box>
                <Box sx={{ fontSize: "30px", fontWeight: 400, background: "linear-gradient(to right, #7044ff, #ee3cff, #ff8029) text", WebkitTextFillColor: "transparent" }}>Partner with the future of Intelligent Solutions. Explore our demos.</Box>
            </Grid>
            <Grid sx={{ position: "absolute", top: "10%", left: "5%", display: "flex", gap: "32px", color: "#FFF" }}>
                {userRole === "aiq-admin" && (
                    <>
                        <Tile icon={asistive} title="CreativeX" onClick={() => NavigateTiles("cx")} />
                        <Tile icon={visual} title="FinServX Agent 1" onClick={() => NavigateTiles("dna")} />
                        <Tile icon={health} title="HealthX" onClick={() => NavigateTiles("health")} />
                        <Tile icon={manufacicon} title="ManufacturingX" onClick={() => NavigateTiles("manufac")} />
                    </>
                )}
                {userRole === "aiq-demo" && <Tile icon={manufacicon} title="ManufacturingX" onClick={() => NavigateTiles("manufac")} />}
                {/* {userRole === "healthx-demo" && <Tile icon={health} title="HealthX" onClick={() => NavigateTiles("health")} />} */}
                {(userRole === "healthx-demo" || userRole === "test-healthx") && <Tile icon={health} title="HealthX" onClick={() => NavigateTiles("health")} />}

                {userRole === "dnaviz-demo" && <Tile icon={visual} title="FinServX Agent 1" onClick={() => NavigateTiles("dna")} />}
                {userRole === "creativex-demo" && <Tile icon={asistive} title="CreativeX" onClick={() => NavigateTiles("cx")} />}
            </Grid>
        </Grid>
    );
};

// Reusable Tile Component
const Tile = ({ icon, title, onClick }) => {
    return (
        <Grid sx={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;", backgroundColor: "#FFF", width: "150px", height: "150px", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", borderRadius: "10px" }}>
            <Tooltip title={<Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{title}</Typography>}>
                <Box component={"img"} src={icon} onClick={onClick} sx={{ width: "100%", cursor: "pointer" }} />
            </Tooltip>
        </Grid>
    );
};

export default Home;
