import React, { useState, useRef } from "react";
import * as speechSdk from "microsoft-cognitiveservices-speech-sdk";
import {
    Button,
    TextField,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";

const API_BASE_URL = "https://controllayer-prod.azurewebsites.net/account/manufacturing_ai/";
// const API_BASE_URL = "http://127.0.0.1:8000/account/manufacturing_ai/"

const Manufacx_summary = () => {
    const [transcription, setTranscription] = useState("");
    const [aiSummary, setAiSummary] = useState({});
    const [listening, setListening] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const speechRecognizerRef = useRef(null);

    const startSpeechRecognition = () => {
        const speechConfig = speechSdk.SpeechConfig.fromSubscription(
            "318269a1df7d4d8a8019734acb625594",
            "eastus"
        );
        speechConfig.speechRecognitionLanguage = "en-US";
        const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);

        let partialTranscription = ""; 

        recognizer.recognizing = (s, e) => {
            if (e.result.reason === speechSdk.ResultReason.RecognizingSpeech) {
                setTranscription(partialTranscription + " " + e.result.text);
            }
        };

        recognizer.recognized = (s, e) => {
            if (e.result.reason === speechSdk.ResultReason.RecognizedSpeech) {
                partialTranscription += " " + e.result.text;
                setTranscription(partialTranscription.trim());
            }
        };

        speechRecognizerRef.current = recognizer;
        recognizer.startContinuousRecognitionAsync();
        setListening(true);
    };

    const stopSpeechRecognition = () => {
        if (speechRecognizerRef.current) {
            speechRecognizerRef.current.stopContinuousRecognitionAsync();
            setListening(false);
        }
    };

    const sendToAiSummaryAPI = async () => {
        if (!transcription.trim()) {
            alert("Please provide a transcription before generating a summary.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL}`, {
                transcription
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status !== 200) {
                throw new Error("Failed to fetch AI summary");
            }

            const data = response.data;
            const parsedData = typeof data === "string" ? JSON.parse(data) : data;

            console.log("AI Summary Data Received:", parsedData);

            localStorage.setItem("aiSummary", JSON.stringify(parsedData));
            setAiSummary(parsedData); 
            setTranscription("");
        } catch (error) {
            console.error("Error fetching AI summary:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card sx={{ width: "100%", margin: "auto", padding: 10, backgroundColor: "#282c34", marginTop: 3 }}>
            <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h5" sx={{ color: "transparent", background: "linear-gradient(90deg, rgb(237, 30, 121) 0%, rgb(0, 113, 188) 100%)", backgroundClip: "text", fontFamily: "Poppins, sans-serif", fontWeight: "bold", fontSize: "24px", letterSpacing: "0.5px", textTransform: "uppercase" }}>
                        ManufacturingX 4.O
                    </Typography>
                </Box>

                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={transcription}
                    onChange={(e) => setTranscription(e.target.value)}
                    placeholder="Start speaking or type manually..."
                    sx={{
                        marginTop: 2,
                        '& .MuiInputBase-input': {
                            color: '#94A3B8',
                        },
                    }}
                />

                <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#0a875a", color: "white", "&:hover": { backgroundColor: "##0a875a" } }}
                        startIcon={listening ? <StopIcon /> : <MicIcon />}
                        onClick={listening ? stopSpeechRecognition : startSpeechRecognition}
                    >
                        {listening ? "Stop" : "Start"}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#7044ff", color: "white", "&:hover": { backgroundColor: "#7044ff" } }}
                        startIcon={<SendIcon />}
                        onClick={sendToAiSummaryAPI}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : "Get AI Summary"}
                    </Button>
                </Box>

                {Object.keys(aiSummary).length > 0 && (
                    <Box sx={{ marginTop: 3 }}>
                        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)} variant="scrollable" scrollButtons="auto">
                            {Object.keys(aiSummary).map((key, index) => (
                                <Tab key={index} label={key.replace(/_/g, " ")} sx={{ color: "white", fontWeight: "bold", fontFamily: "Poppins, sans-serif" }} />
                            ))}
                        </Tabs>

                        <Box sx={{ padding: 2, background: "#f5f5f5", borderRadius: 2, marginTop: 2 ,    }}>
                            
                            {Object.keys(aiSummary)[tabIndex] === "action_items" ? (
                                <Box sx={{ overflow: "auto", height: "455px"}}>
                                    {aiSummary.action_items.map((item, index) => (
                                       <Box 
                                       key={index} 
                                       sx={{ 
                                           padding: 2, 
                                           background: "#e0e0e0", 
                                           borderRadius: 2, 
                                           marginBottom: 1, 
                                           textAlign: "left",  
                                        //    direction: "rtl"      
                                       }}
                                   >
                                       <Typography 
                                           variant="body2" 
                                           sx={{  fontFamily: "Poppins, sans-serif" }}
                                       >
                                         <strong> Task: </strong>   {item.task}
                                       </Typography>
                                       <Typography 
                                           variant="body2" 
                                           sx={{ fontFamily: "Poppins, sans-serif" }}
                                       >
                                           <strong>Assigned To:</strong> {item.assigned_to}
                                       </Typography>
                                       <Typography 
                                           variant="body2" 
                                           sx={{ fontFamily: "Poppins, sans-serif" }}
                                       >
                                           <strong>Deadline:</strong> {item.deadline}
                                       </Typography>
                                   </Box>
                                   
                                    ))}
                                    <Typography variant="h8" sx={{ marginTop: 2, fontWeight: "bold" }}>
                                        Total Action Items: {aiSummary.action_items.length}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography>
                                    {aiSummary[Object.keys(aiSummary)[tabIndex]] || "No data available."}
                                </Typography>
                            )}

                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default Manufacx_summary;


