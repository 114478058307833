import { useState, useEffect } from "react";
import "../styles/Header.css";
import logo from "../assets/AIQ_Logo_Black.svg"
import aiq_logo from "../assets/Wolverine_AIQ-01.svg"
import Dropdown from "../assets/icons/Dropdown.svg";
import Search from "../assets/icons/Search.svg";
import Hamburger from "../assets/icons/Hamburger-Menu.svg";
import MobileSearch from "../assets/icons/Mobile-Search.svg";
import { Link, useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BlueArrowRight from "../assets/icons/Blue-Arrow-Right.svg";
import asistive from "../assets/icons/design-ideas_svgrepo.com.svg"
import visual from "../assets/icons/dimensional-view_svgrepo.com.svg"
import { Tooltip, Typography } from "@mui/material"; 
import Segmentation_svg from "../assets/Connection.svg"
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { updateInitialState } from "../redux/initialData";
import health from "../assets/icons/circle-add-svgrepo-com.svg"

export default function Header() {
	let navigate=useNavigate()
	const dispatch = useDispatch()
	let [mobileOpen, setMobileOpen] = useState(false);
	let [searchOpen, setSearchOpen] = useState(false);
	let [search, setSearch] = useState("");``
	let accesskey=localStorage.getItem('token') || sessionStorage.getItem('lo');
	console.log("accesss token ", accesskey)
	function toggleMobile() {
		setMobileOpen(() => !mobileOpen);
	}

	function toggleSearch() {
		// Auto-focus search when opening it
		if (!searchOpen) {
			document.querySelector("#search input[type='search']").focus();
		}
		setSearchOpen(() => !searchOpen);
	}

	function onSearchInput(e) {
		setSearch(() => e.target.value);
	}

	function logout(){
		// localStorage.removeItem("token")
		navigate("/")
	}
	useEffect(()=>{
        if(accesskey){
            dispatch(updateInitialState({key:"login_token",value:accesskey}))
        }
    },[])
	return (
		<div id="header-container">
			<div id="header">
				<div style = {{display:"flex", flexDirection:"row"}}>
				{/* <a href="/" id="header-logo">
					<img src={aiq_logo} style={{width:167}} alt="Wolverine, a next-generation AI" />
				</a> */}
                <a href="/dashboard" id="header-logo">
					<img src={logo} style={{width:72, marginRight:"100px"}} />
				</a>
				</div>
				<div id="header-sections">
					{/* {accesskey ? (
						<>
							<a href="/">Home</a>
						</>
					) : (
						<>
							<a href="/">
								{location.pathname === "/" ? (
									<b>Home</b>
								) : (
									"Home"
								)}
							</a>
							{/* <a href="#">About</a> 
							{/* <a href="#">
								<div>
									Products <img src={Dropdown} alt="" />
								</div>
							</a> 
						</>
					)} */}
					<div>
						{/* <div>
							{["/assitive_ai", "/csv_qa"].includes(
								location.pathname
							) ? (
								<b>Solutions</b>
							) : (
								"Solutions"
							)}
							<img src={Dropdown} alt="" />
						</div> */}
						<div className="header-dropdown">
							{accesskey && <Link to="/assitive_ai" className="listing" >
								
								<Box sx={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",backgroundColor:"#FFF",width:"100px",height:"100px",display:"flex",flexDirection:"column",alignContent:"center",alignItems:"center",borderRadius:"10px"}}>
									<Tooltip arrow title={<Typography sx={{fontSize:"20px",fontWeight:500}}>CreativeX</Typography>}>

									<img src={asistive} style={{width:"100px"}}/>
									</Tooltip>
								</Box>
								<div>
									{/* <h4>Creative Experience</h4> */}
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> */}
								</div>
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> */}
							</Link>}
							{accesskey && <Link to="/csv_qa" className="listing" >
								<Box sx={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",backgroundColor:"#FFF",width:"100px",height:"100px",display:"flex",flexDirection:"column",alignContent:"center",alignItems:"center",borderRadius:"10px"}}>
									<Tooltip arrow title={<Typography sx={{fontSize:"20px",fontWeight:500}}>FinServX Agent 1</Typography>}>
										<img src={visual} style={{width:"100px"}}/>
									</Tooltip>
								</Box>
									<div>
									{/* <h4 style={{color: "#FD8819"}}>Visualization</h4> */}
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> */}
								</div>
								
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> */}
							</Link>}
							{/* {accesskey && <a href="/segmen_ai" className="listing" >
								<img src={Segmentation_svg} style={{width:"100px"}}/>
								<div>
									<h4 style={{color: "#3B82F6"}}>Segment Analysis</h4>
								</div>
							</a>} */}

                                 {accesskey && <Link to="/health_x" className="listing" >
								
								<Box sx={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",backgroundColor:"#FFF",width:"100px",height:"100px",display:"flex",flexDirection:"column",alignContent:"center",alignItems:"center",borderRadius:"10px"}}>
									<Tooltip arrow title={<Typography sx={{fontSize:"20px",fontWeight:500}}>HealthX</Typography>}>

									<img src={health} style={{width:"100px"}}/>
									</Tooltip>
								</Box>
								<div>
									{/* <h4>Creative Experience</h4> */}
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> */}
								</div>
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> */}
							</Link>}
						</div>
					</div>
					 {/* <a href="https://arivu-iq.com/#ourContact" target="_blank">Contact</a> */}
				</div>
				<div id="header-buttons">
					{/* <button className="search-button" onClick={toggleSearch}>
						<img src={Search} alt="Search" />
						<input
							id="large-search"
							className={searchOpen ? "search-open" : ""}
							type="search"
							placeholder="Search..."
							value={search}
							onInput={onSearchInput}
							onClick={e => e.stopPropagation()}
						/>
					</button> */}
					{/* {accesskey ? ( */}
						{/* <Tooltip title="Logout">
						<button className="get-logout" onClick={logout}>
							<PowerSettingsNewIcon />
						</button>
						</Tooltip> */}
					{/* ) : ( */}
						{!accesskey ?<a href="/login">
							<button className="get-started font orange-on-blue">
								{/* Get started */}
								Login
							</button>
						</a>
						:
						<a href="/login">
							<button className="get-started font orange-on-blue" onClick={()=>{localStorage.removeItem('token');localStorage.removeItem('selected_tile')}}>
								{/* Get started */}
								Logout
							</button>
						</a>	
					}
					{/* )} */}
				</div>
				<button id="header-mobile-button" onClick={toggleMobile}>
					<img src={Hamburger} alt="open menu" />
				</button>
			</div>
			{mobileOpen && (
				<div id="header-mobile">
					{/* <div id="mobile-search">
						<input
							type="text"
							placeholder="Search"
							value={search}
							onInput={onSearchInput}
						/>
						<button>
							<img src={MobileSearch} alt="Search" />
						</button>
					</div> */}
					<a className="mobile-section" href="/">
						Home
					</a>
					{/* <a className="mobile-section" href="#">
						Products
					</a> */}
					<a className="mobile-section" href="/assitive_ai">
					Creative Experience
					</a>
					<a className="mobile-section" href="/csv_qa">
					Visualization
					</a>
					<a
						className="mobile-section"
						href="https://arivu-iq.com/#ourContact" 
						target="_blank"
						onClick={toggleMobile}
					>
						Contact Us
					</a>
					{/* {accesskey ? (
						<a
							className="mobile-section login-highlight"
							onClick={logout}
						>
							Logout
						</a>
					) : (
						<a
							className="mobile-section login-highlight"
							href="/login"
						>
							<b>Get Started</b>
						</a>
					)} */}
				</div>
			)}
			<div id="ghost-header"></div>
			{/* <div id="search" className={searchOpen ? "search-open" : ""}>
				<input
					type="search"
					placeholder="Search..."
					value={search}
					onInput={onSearchInput}
				/>
				<button>Search</button>
			</div> */}
		</div>
	);
}
