import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Index from "./pages/Index";
import Login from "./pages/Login";
import Signup from "./pages/Signup";

import IstoryBoard from "./pages/istoryBoard";
import DataAnalysis from "./pages/DNA";

import { Box, CircularProgress } from "@mui/material";
import useFetch from "./components/useFetch";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import  Segmentation  from "./pages/segmentation";
import Home from "./pages/home";
import { useSelector } from "react-redux";
import HealthX from "./pages/health";
import ManufactureX from "./pages/manufacturex";


function App() {
	// const { loading, error, websiteContent } = useFetch(`https://cms.istorylive.com/api/nlu-websites/1/?populate=*`);
  
	// if (loading) {
	// 	return (
	// 		<Box
	// 			display="flex"
	// 			justifyContent="center"
	// 			alignItems="center"
	// 			height="100vh"
	// 		>
	// 			<CircularProgress />
	// 		</Box>
	// 	);
	// }
	// if (error) return <p> Error : </p>;
	let token = useSelector(state=>state.initialData?.login_token)
	return (
		<>
			<div>
				<Routes>
					{/* <Route exact path="/" element={<Index content={websiteContent} />}></Route> */}
					{/* <Route exact path="/" element={<Home/>}></Route> */}
					<Route exact path="/" element={<Navigate to="/login" />} />
					<Route exact path="/dashboard" element={<Home />} />


					<Route exact path="/login" element={token ? <Navigate to ="/"/> :<Login />}></Route>
					{/*<Route exact path="/signup" element={<Signup />}></Route> */}
					{/* <Route exact path="/signup" element={<Signup />}></Route> */}
					{
						token === "dataaccess" &&<>
							<Route exact path="/assitive_ai" element={<IstoryBoard/>}></Route>
							<Route exact path="/csv_qa" element={<DataAnalysis/>}></Route>
							<Route exact path="/segmen_ai" element={<Segmentation/> }></Route>
							<Route exact path="/health_x" element={<HealthX/>}></Route>
							<Route exact path="/manufac_x" element={<ManufactureX/>}></Route>
						</>
					}
					

					{/* <Route exact path="/health_x" element={<HealthX/>}></Route> */}

					{/* <Route exact path="/segmen_ai" element={accessToken ? <Segmentation/> : <Navigate to="/login"/>}></Route> */}
					{/* <Route exact path="/terms" element={<Terms content={websiteContent}/>}></Route>
					<Route exact path="/privacy" element={<Privacy content={websiteContent}/>}></Route> */}
				</Routes>
			</div>
		</>
	);
}

export default App;
