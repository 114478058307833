import React, { useState, useRef, useEffect } from "react";
import * as speechSdk from "microsoft-cognitiveservices-speech-sdk";
import {
    Button,
    TextField,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    Box,
    IconButton,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import SendIcon from "@mui/icons-material/Send";
import DarkModeIcon from "@mui/icons-material/NightsStay";
import LightModeIcon from "@mui/icons-material/WbSunny";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import axios from "axios";
import { Divider } from "@mui/material";

 
 
const API_BASE_URL = "https://controllayer-prod.azurewebsites.net/account/generate-medical-summary/";
//const API_BASE_URL = "http://127.0.0.1:8000/conversational-agents/generate-medical-summary/"
 
const SpeechTest = () => {
    const [transcription, setTranscription] = useState("");
    const [aiSummary, setAiSummary] = useState('');
    const [listening, setListening] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMedical, setLoadingMedical] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [darkMode, setDarkMode] = useState(false);
    const speechRecognizerRef = useRef(null);
    const [medicalAnalysis, setMedicalAnalysis] = useState([]); 
    // const audioRef = useRef(new Audio("./venkat1.wav"));
    const audioRef = useRef(new Audio("healthxaudio.wav"));
 
    const [isPlaying, setIsPlaying] = useState(false);
    const [userRole, setUserRole] = useState(null);


    const [medicalTabIndex, setMedicalTabIndex] = useState(0);
    const [aiSummaryTabIndex, setAiSummaryTabIndex] = useState(0);


    useEffect(() => {
        // Retrieve the user role from localStorage
        const role = localStorage.getItem("user_role");
        setUserRole(role);
    }, []);
    
    // const setAudioRef = "./venkat1.wav";
 
 
    // useEffect(() => {
    //     //const savedSummary = localStorage.getItem("aiSummary");
    //     if (savedSummary) {
    //         setAiSummary(JSON.parse(savedSummary));
    //     }
    // }, []);
 
    // const theme = createTheme({
    //     palette: {
    //         mode: darkMode ? "dark" : "light",
    //     },
    // });
 
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };
 
     // 🎵 Play/Pause Sample Audio
     const toggleSampleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
 
        audioRef.current.onended = () => setIsPlaying(false);
    };
 
    // 📜 Set Sample Transcription in Textbox
    const setSampleTranscription = () => {
        setTranscription(`
            The patient, a diagnosed **stomach cancer** patient undergoing **chemotherapy**, presents with **severe fatigue, unintentional weight loss, and persistent abdominal pain** that has worsened over the past month. Additionally, they report **night sweats, loss of appetite, and occasional nausea**, which could be attributed to either disease progression or chemotherapy side effects. Despite no signs of fever or abnormal bleeding, the patient's **progressive weakness and nutritional decline** raise concerns about potential **malnutrition and metabolic imbalances**. Given their symptoms, immediate priorities include **symptom relief, nutritional intervention, and further diagnostic evaluations**. A **comprehensive blood panel** has been ordered to assess **hemoglobin levels, liver and kidney function, and tumor markers (CEA, CA 19-9)** to monitor cancer progression. Imaging studies, including **a CT scan of the abdomen and pelvis, and potentially a PET scan**, will help determine whether there is **disease metastasis or treatment resistance**. In the meantime, the patient has been prescribed **anti-nausea medication (Ondansetron) to manage chemotherapy-induced nausea, Acetaminophen for pain relief, and a high-protein nutritional plan to counter weight loss and fatigue**. Hydration support and **multivitamin supplements** have also been recommended to prevent further decline. Given their current condition, **close monitoring is necessary, with a follow-up scheduled in one week** for lab results and nutritional assessment, along with an **oncology consultation in two weeks** to evaluate chemotherapy effectiveness and potential treatment adjustments.
        `);
        // setTranscription(`
        //     For hypertension, the patient takes Losartan and Amlodipine to lower high blood pressure.
        // `);
    };
 
 
    const startSpeechRecognition = () => {
        const speechConfig = speechSdk.SpeechConfig.fromSubscription(
            "318269a1df7d4d8a8019734acb625594",
            "eastus"
        );
        speechConfig.speechRecognitionLanguage = "en-US";
        const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
    
        let partialTranscription = ""; // Store ongoing speech without duplication
    
        recognizer.recognizing = (s, e) => {
            if (e.result.reason === speechSdk.ResultReason.RecognizingSpeech) {
                // Only update the textbox temporarily without overwriting full text
                setTranscription(partialTranscription + " " + e.result.text);
            }
        };
    
        recognizer.recognized = (s, e) => {
            if (e.result.reason === speechSdk.ResultReason.RecognizedSpeech) {
                // Append the full recognized sentence to the transcription
                partialTranscription += " " + e.result.text;
                setTranscription(partialTranscription.trim());
            }
        };
    
        speechRecognizerRef.current = recognizer;
        recognizer.startContinuousRecognitionAsync();
        setListening(true);
    };
    
 
    const stopSpeechRecognition = () => {
        if (speechRecognizerRef.current) {
            speechRecognizerRef.current.stopContinuousRecognitionAsync();
            setListening(false);
        }
    };
 
    const sendToAiSummaryAPI = async () => {
        if (!transcription.trim()) {
            alert("Please provide a transcription before generating a summary.");
            return;
        }
    
        setLoading(true);
    
        try {
            // Sending POST request with Axios
            const response = await axios.post(`${API_BASE_URL}`, {
                transcription
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    
            // Check if the response status is OK (200-299)
            if (response.status !== 200) {
                throw new Error("Failed to fetch AI summary");
            }
    
            // Extract the data from the response
            const data = response.data;
            const parsedData = typeof data === "string" ? JSON.parse(data) : data;
    
            console.log("AI Summary Data Received:", data);
    
            // Store the data in localStorage and update state
            localStorage.setItem("aiSummary", JSON.stringify(data));
            setAiSummary(parsedData);
            setTranscription(""); // Reset input field after response
        } catch (error) {
            console.error("Error fetching AI summary:", error);
        } finally {
            // Set loading state back to false after request completes
            setLoading(false);
        }
    };
    console.log(typeof(aiSummary))
    

    const sendToAnalyzeMedicalAPI = async () => {
        if (!transcription.trim()) {
            alert("Please provide a transcription before analyzing.");
            return;
        }
    
        setLoadingMedical(true);
    
        try {
            const response = await axios.post("https://controllayer-prod.azurewebsites.net/account/analyze-medical-text/", 
                { text: transcription }, 
                {
                    headers: { "Content-Type": "application/json" }
                }
            );
    
            if (response.status !== 200) {
                throw new Error("Failed to analyze medical text");
            }
    
            const data = response.data;
            console.log("Medical Text Analysis Data Received:", data);
    
            // Store result in state
            setMedicalAnalysis(Array.isArray(data.analysis) ? data.analysis : []);
            localStorage.setItem("medicalAnalysis", JSON.stringify(data.analysis));
        } catch (error) {
            console.error("Error analyzing medical text:", error);
            alert("Error processing medical text. Try again.");
        } finally {
            setLoadingMedical(false);
        }
    };
    
    
 
    return (
        <Card sx={{ width: "100%", margin: "auto", padding: 5, backgroundColor: "#282c34", marginTop: 3 }}>
            <CardContent>
                {/* Header */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h5" sx={{ 
                        color: "transparent", 
                        background: "linear-gradient(90deg, rgb(237, 30, 121) 0%, rgb(0, 113, 188) 100%)",  
                        backgroundClip: "text", 
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                        fontSize: "24px",
                        letterSpacing: "0.5px", 
                        textTransform: "uppercase"
                    }}>
                        HealthX
                    </Typography>
                </Box>
    
                {/* Buttons */}
                <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#ff9900", color: "#33336f", "&:hover": { bgcolor: "#ff9900", color: "#33336f" } }} 
                        startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                        onClick={toggleSampleAudio}
                    >
                        {isPlaying ? "Pause Sample" : "Play Sample"}
                    </Button>
    
                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#EE3CFF", color: "#33336f", "&:hover": { bgcolor: "#EE3CFF", color: "#33336f" } }} 
                        onClick={setSampleTranscription}
                    >
                        Use Sample Transcription
                    </Button>
                </Box>
    
                {/* Transcription Input */}
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={transcription}
                    onChange={(e) => setTranscription(e.target.value)}
                    placeholder="Start speaking or type manually..."
                    sx={{ marginTop: 2, '& .MuiInputBase-input': { color: '#94A3B8' } }}
                />
    
                {/* Action Buttons */}
                <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#0a875a", color: "white", "&:hover": { backgroundColor: "#0a875a" } }}
                        startIcon={listening ? <StopIcon /> : <MicIcon />}
                        onClick={listening ? stopSpeechRecognition : startSpeechRecognition}
                    >
                        {listening ? "Stop" : "Start"}
                    </Button>
    
                    {/* Get AI Summary Button */}
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#7044ff", color: "white", "&:hover": { backgroundColor: "#7044ff" } }}
                        startIcon={<SendIcon />}
                        onClick={sendToAiSummaryAPI}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : "Get AI Summary"}
                    </Button>
    
                    {/* Medical Term Analysis Button */}
                    {userRole === "test-healthx" && (
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#7044ff", color: "white", "&:hover": { backgroundColor: "#7044ff" } }}
                        startIcon={<SendIcon />}
                        onClick={sendToAnalyzeMedicalAPI}
                        disabled={loadingMedical}
                    >
                        {loadingMedical ? <CircularProgress size={24} /> : "Extract Medical Terms"}
                    </Button>)}
                </Box>
    
                {/* Medical Terms & Description Display */}
                {medicalAnalysis.length > 0 && (
                    <Box sx={{ marginTop: 3 }}>
                        {/* Tabs for Medical Terms & Descriptions */}
                        <Tabs
                            value={medicalTabIndex}
                            onChange={(e, newValue) => setMedicalTabIndex(newValue)}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{ color: "white" }}
                        >
                            <Tab label="Medical Terms" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Descriptions" sx={{ color: "white", fontWeight: "bold" }} />
                        </Tabs>
    
                        {/* ✅ Scrollable Box for Medical Terms */}
                        <Box 
                            sx={{ 
                                padding: 2, 
                                background: "#f5f5f5", 
                                marginTop: 2,
                                maxHeight: "400px",  
                                overflowY: "auto",   
                                width: "100%",       
                                boxShadow: 1,
                            }}
                        >
                            {/* Medical Terms with Vocabulary Codes */}
                            {medicalTabIndex === 0 && (
                                <Typography>
                                    {medicalAnalysis.map((item, index) => (
                                        <Box key={index} sx={{ paddingY: 1 }}>
                                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                {item.medicine || "Unknown Term"}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <span style={{ fontWeight: "bold", color: "black" }}>Vocabulary Codes:</span> 
                                                {Array.isArray(item.vocab_codes) && item.vocab_codes.length > 0 
                                                    ? ` [${item.vocab_codes.join(", ")}]` 
                                                    : " None"}
                                            </Typography>
                                            {index < medicalAnalysis.length - 1 && <Divider sx={{ marginY: 1, background: "black" }} />} 
                                        </Box>
                                    ))}
                                </Typography>
                            )}

    
                            {/* Descriptions with Divider */}
                            {medicalTabIndex === 1 && (
                                <Typography sx={{ textAlign: "justify" }}>
                                    {medicalAnalysis.map((item, index) => (
                                        <Box key={index} sx={{ paddingY: 1 }}>
                                            <Typography 
                                                variant="body1" 
                                                sx={{ fontWeight: "bold", display: "inline", color: "black" }}
                                            >
                                                {item.medicine || "Unknown Medicine"}: 
                                            </Typography>
                                            <Typography 
                                                variant="body2" 
                                                sx={{ display: "inline", marginLeft: 1, textAlign: "justify", wordBreak: "break-word" }}
                                            >
                                                {item.description || "No description available."}
                                            </Typography>
                                            {index < medicalAnalysis.length - 1 && <Divider sx={{ marginY: 1, background: "black" }} />} 
                                        </Box>
                                    ))}
                                </Typography>
                            )}



                        </Box>
                    </Box>
                )}
    
                {/* AI Summary Display */}
                {aiSummary && (
                    <Box sx={{ marginTop: 3 }}>
                        <Tabs
                            value={aiSummaryTabIndex}
                            onChange={(e, newValue) => setAiSummaryTabIndex(newValue)}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{ color: "white" }}
                        >
                            <Tab label="Observation Report" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Doctor Notes" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Patient Symptoms" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Prescription Given" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Follow-Up Date" sx={{ color: "white", fontWeight: "bold" }} />
                            <Tab label="Lab Tests Needed" sx={{ color: "white", fontWeight: "bold" }} />
                        </Tabs>
    
                        <Box sx={{ padding: 2, background: "#f5f5f5", borderRadius: 2, marginTop: 2 }}>
                            {aiSummaryTabIndex === 0 && <Typography>{aiSummary?.observation_report || "No data available."}</Typography>}
                            {aiSummaryTabIndex === 1 && <Typography>{aiSummary?.doctor_notes || "No data available."}</Typography>}
                            {aiSummaryTabIndex === 2 && <Typography>{aiSummary?.patient_symptoms || "No symptoms listed."}</Typography>}
                            {aiSummaryTabIndex === 3 && <Typography>{aiSummary?.prescription_given || "No prescription available."}</Typography>}
                            {aiSummaryTabIndex === 4 && <Typography>{aiSummary?.follow_up_date || "No follow-up date set."}</Typography>}
                            {aiSummaryTabIndex === 5 && <Typography>{aiSummary?.lab_tests_needed || "No lab tests needed."}</Typography>}
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};
 
export default SpeechTest;