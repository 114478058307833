
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    login_token : ""
};


const initialDataReducer = createSlice({
    name: "InitialDataReducer",
    initialState: initialState,
    reducers: {
        updateInitialState: (state, { type, payload }) => {
            state[payload.key] = payload.value
        },
        
    }
    })

    export const { 
        updateInitialState
    } = initialDataReducer.actions
    
    
    
    export default initialDataReducer.reducer;