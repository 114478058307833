import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import Header from "../components/Header.js";
import Manufacx_summary from "./manufacx.js";
import ManufacSideBar from "../components/manufacxSidebar.js";
 
const drawerWidth = 260; // Sidebar width
 
const ManufactureX = ({ children }) => {
    const [selectedPage, setSelectedPage] = useState("patients");
 
    const handleNavigation = (page) => {
        setSelectedPage(page); // ✅ Allow switching between "Patients" & "Sample Data"
    };
    return (
        <>
            <CssBaseline />
            <Box sx={{ display: "flex",textAlign:"center",backgroundColor: "#282c34", color:"white",position:"absolute",top:0, bottom:0, left:0, right:0}}>
                <Header/>
                {/* Sidebar */}
                <ManufacSideBar selectedPage={selectedPage} handleNavigation={handleNavigation} />
                
                {/* Full-width content area */}
                <Box component="main" sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${drawerWidth}px)`,
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden"
                }}>
                    {selectedPage === "patients" && <Manufacx_summary/>}
                    {/* {selectedPage === "sample" && <SampleAudioTest />}  ✅ Show Sample Data Page */}
                </Box>
            </Box>
        </>
    );
};
 
export default ManufactureX;